<template>
  <div id="myCourses">
    <v-container class="mt-12">
      <v-row v-if="courses.length > 0">
        <v-col cols="12" md="10" offset-md="1">
          <v-row>
            <v-col v-for="(item, i) in courses" :key="i" md="4" class="mb-4">
              <course-card
                :courseName="item.courseName"
                :courseImg="item.courseImg"
                :instructor="item.instructor"
                :courseUrlCode="item.courseUrlCode"
                :courseStartDate="item.courseStartDate"
                :courseFinishDate="item.courseFinishDate"
                :startDate="item.startDate"
                :finishDate="item.finishDate"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center my-12">
              <v-btn
                rounded
                large
                elevation="24"
                :to="{ name: 'course-catalog' }"
                color="primary"
                >DİĞER EĞİTİMLERE GÖZAT</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col cols="12" md="6" offset-md="3" class="my-12">
          <div class="msg">
            <v-icon size="60" class="mb-4" color="secondary">info</v-icon>
            <p>Devam eden eğitiminiz bulunmamaktadır.</p>
            <p class="pt-6">
              <v-btn
                rounded
                large
                :to="{ name: 'course-catalog' }"
                color="primary"
                >EĞİTİMLERE GÖZAT</v-btn
              >
            </p>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import firebase from "@/plugins/firebase";
import CourseCard from "@/components/CourseRegCard.vue";

export default {
  metaInfo: () => ({
    title: "Eğitimlerim"
  }),

  components: {
    CourseCard
  },

  data: () => ({
    courses: []
  }),

  computed: {
    user() {
      return this.$store.getters["auth/user"];
    }
  },

  methods: {
    async getCourseRegs() {
      try {
        // Get registered courses of current user
        const qsCregs = await firebase
          .firestore()
          .collection("/apps/kahev-akademi/courseRegs")
          .where("uid", "==", this.user.id)
          .get();

        console.log(qsCregs.size);

        qsCregs.forEach(async docCreg => {
          const creg = docCreg.data();

          creg.id = docCreg.id;
          creg.courseId = docCreg.data().courseId;
          creg.uid = docCreg.data().uid;
          creg.startDate = docCreg.data().startDate.toDate();
          creg.finishDate = docCreg.data().finishDate.toDate();

          // Get course infos
          const qsCourse = await firebase
            .firestore()
            .doc("/apps/kahev-akademi/courses/" + creg.courseId)
            .get();

          creg.courseUrlCode = qsCourse.data().urlCode;
          creg.courseName = qsCourse.data().name;
          creg.courseImg = qsCourse.data().img;
          creg.instructor = qsCourse.data().instructor;
          creg.courseStartDate = qsCourse.data().startDate
            ? qsCourse.data().startDate.toDate()
            : null;
          creg.courseFinishDate = qsCourse.data().finishDate
            ? qsCourse.data().finishDate.toDate()
            : null;

          this.courses.push(creg);
        });
      } catch (error) {
        throw `Kayıtlı olunan eğitimler alınamadı: ${error}`;
      }
    }
  },

  mounted() {
    this.getCourseRegs();
  }
};
</script>

<style lang="scss">
#myCourses {
  .msg {
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    text-align: center;
    background-color: #fff;
    padding: 30px;
    margin-top: 10px;
  }
}
</style>
