<template>
  <v-card class="max-auto course">
    <v-img :src="courseImg" height="240" position="center bottom" />
    <v-card-title>{{ courseName }}</v-card-title>
    <v-card-subtitle>{{ instructor }}</v-card-subtitle>
    <v-card-text>
      Bu eğitime erişiminiz
      <strong>{{ finishDate | formatDate }}</strong> tarihinde sona erecek.
    </v-card-text>
    <v-card-actions>
      <v-btn
        text
        color="primary"
        rounded
        :to="{
          name: 'course-home',
          params: { courseUrlCode: courseUrlCode }
        }"
      >
        EĞİTİMİ AÇ
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  props: {
    id: String,
    uid: String,
    courseId: String,
    courseName: String,
    courseUrlCode: String,
    courseImg: String,
    instructor: String,
    courseStartDate: Date,
    courseFinishDate: Date,
    startDate: Date,
    finishDate: Date
  },

  filters: {
    formatDate(v) {
      return v.toLocaleString("tr-TR", {
        dateStyle: "full",
        timeStyle: "short"
      });
    }
  }
});
</script>

<style lang="scss">
.course {
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1) !important;
  border-radius: 15px !important;
  transition: all ease 0.3s !important;

  &:hover {
    transform: scale(1.05);
  }

  .v-card__title {
    min-height: 50px;
    align-items: flex-start;
  }

  .v-card__subtitle {
    span {
      display: inline-block;
      width: 25px;
      height: 5px;
      border-radius: 2px;
      margin-left: 10px;
    }
  }
}
</style>
